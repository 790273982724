import React, { lazy, useCallback } from "react";
import { Button } from "@material-tailwind/react";
import { IoIosVideocam } from "react-icons/io";
import DelayedSuspense from './DelayedSuspense';  // Ensure the correct path
import cover from '../asstes/backimg.jpg';

const CallToAction = lazy(() => import("./CallToAction"));
const GetInTouch = lazy(() => import("./GetInTouch"));

export default function Cover() {
    const handleBookCall = useCallback(() => {
        window.open('https://calendly.com/zain12/30min', '_blank');
    }, []);

    const handleGetInTouch = useCallback(() => {
        window.open('https://www.linkedin.com/in/zaindev/', '_blank');
    }, []);

    return (
        <div className="relative">
            <div className="absolute inset-0 animated-background -z-10"></div>
            <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between">
                <div className="mx-auto max-w-2xl py-28 lg:py-15">
                    <div className="hidden sm:mb-8 sm:flex sm:justify-center">
                        <div className="relative rounded-full px-3 py-1 text-sm leading-6 text-gray-600 ring-1 ring-gray-100/10 hover:ring-gray-900/20">
                            Your Partner in Software Excellence.{' '}
                            <a href="#" className="font-semibold text-indigo-500">
                                <span className="absolute inset-0" aria-hidden="true" />
                                Hire Us <span aria-hidden="true">&rarr;</span>
                            </a>
                        </div>
                    </div>
                    <div className="text-center">
                        <h1 className="text-3xl font-bold tracking-tight text-gray-200 sm:text-4xl">
                            We've got an entire team dedicated to support you and your business
                        </h1>
                        <p className="mt-6 text-lg leading-8 text-gray-600">
                            Our team of expert developers are always available to help you get started
                        </p>
                        <div className="mt-10 flex items-center flex-col justify-center gap-x-6 sm:flex-row sm:justify-center gap-y-6">
                                <CallToAction handleBookCall={handleBookCall} />
                                <GetInTouch handleGetInTouch={handleGetInTouch} />
                        </div>
                    </div>
                </div>
                <div
                    className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
                    aria-hidden="true"
                >
                    <div
                        className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#A6C5FF] to-[#9089fc] opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
                        style={{
                            clipPath:
                                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
                        }}
                    />
                </div>
            </div>
        </div>
    );
}
