import React from "react";
import { NavbarWithMegaMenu } from "./components/Navbar";
import Aos from "aos";
import "aos/dist/aos.css";
import Cover from "./components/cover";
import Team from "./components/team";
import Contact from "./components/contact";
import { FooterWithSitemap } from "./components/footer";
import Patners from "./components/patners";
import Testomonial from "./components/testomonial";
import Message from "./components/ceo";
import WorkwithUs from "./components/workwithus";
import PortfolioSection from "./components/PortfolioSection";
import FoundersComponent from "./components/founder";

export default function Home() {

  Aos.init({ duration: 1000 });

  return (
    <div className="flex flex-col">
      <div data-aos="fade-up" data-aos-duration="1000">
        <Cover />
      </div>
      
      
      
      <div data-aos="fade-up" data-aos-duration="1000">
        <Contact />
      </div>
      <div data-aos="fade-up" data-aos-duration="1000">
      <PortfolioSection />
      </div>
      <div data-aos="fade-up" data-aos-duration="1000">
        <Patners />
      </div>
      <div data-aos="fade-up" data-aos-duration="1000">
        <Testomonial />
      </div>
     
      <div data-aos="fade-up" data-aos-duration="1000">
        <Message />
      </div>
      <div data-aos="fade-up" data-aos-duration="1000">
        {/* <WorkwithUs /> */}
      </div>
    </div>
  );
}