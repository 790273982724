import { CloudArrowUpIcon, LockClosedIcon, ServerIcon } from '@heroicons/react/24/outline'
import { CurrencyDollarIcon } from '@heroicons/react/24/outline'

export default function Details() {
  return (
    <div className="relative isolate overflow-hidden bg-black px-6 py-24 sm:py-32 lg:overflow-visible lg:px-0">
      <div className="absolute inset-0 -z-10 overflow-hidden">
      
      </div>
      <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 lg:mx-0 lg:max-w-none lg:grid-cols-2 lg:items-start lg:gap-y-10">
        <div className="lg:col-span-2 lg:col-start-1 lg:row-start-1 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
          <div className="lg:pr-4">
            <div className="lg:max-w-lg">
              <p className="text-base font-semibold leading-7 text-indigo-400">More about us</p>
              <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-200 sm:text-4xl">Skylane is a full-service Software Development Company</h1>
              <p className="mt-6 text-xl leading-8 text-gray-400">
                We are a team of 100+ highly-skilled software engineers, UI/UX designers, and project managers who are always ready to take on new challenges to help businesses scale.
              </p>
            </div>
          </div>
        </div>
        <div className="-ml-12 -mt-12 p-12 lg:sticky lg:top-4 lg:col-start-2 lg:row-span-2 lg:row-start-1 lg:overflow-hidden">
          <img
            className="w-[48rem] max-w-none rounded-xl bg-gray-900 shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem]"
            src="https://images.unsplash.com/photo-1521737604893-d14cc237f11d?q=80&w=1784&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
            alt=""
          />
        </div>
        <div className="lg:col-span-2 lg:col-start-1 lg:row-start-2 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8 text-justify">
          <div className="lg:pr-4">
            <div className="max-w-xl text-base leading-7 text-gray-400 lg:max-w-lg">
              <p>
                The Skylane team is made up of highly-skilled Full Stack Developers, UI/UX Designers, and Project
                Managers who are always ready to take on new challenges to help businesses scale. We have experience
                in building web applications, mobile apps, and custom software solutions for clients around the world.
                </p>
              <ul role="list" className="mt-8 space-y-8 text-gray-400">
                <li className="flex gap-x-3">
                  <CloudArrowUpIcon className="mt-1 h-5 w-5 flex-none text-indigo-400" aria-hidden="true" />
                  <span>
                    <strong className="font-bold text-indigo-400">99.9% uptime guarantee </strong>
                    We're so confident in our uptime, we publish our uptime statistics every month.
                  </span>
                </li>
                <li className="flex gap-x-3">
                  <LockClosedIcon className="mt-1 h-5 w-5 flex-none text-indigo-400" aria-hidden="true" />
                  <span>
                    <strong className="font-bold text-indigo-400">Cutting edge security </strong>
                    We take security seriously. We use the latest encryption technology to protect your data.
                    The servers are monitored 24/7 to ensure that your data is safe and secure.
                  </span>
                </li>
                <li className="flex gap-x-3">
                  <CurrencyDollarIcon className="mt-1 h-5 w-5 flex-none text-indigo-400" aria-hidden="true" />
                  <span>
                    <strong className="font-bold text-indigo-400">Budget friendly Plans </strong>
                    We offer our development services at affordable prices. Our plans are designed to fit your budget and
                    needs.
                  </span>
                </li>
              </ul>
              <p className="mt-8">
                If you're a stakeholder in a business, you know that the success of your company depends on the
                performance of your website. You need a reliable hosting provider that can handle the traffic spikes
                and keep your site online. That's where we come in. We offer a variety of hosting plans to meet your
                needs. Whether you're looking for a simple shared hosting plan or a dedicated server, we have the
                solution for you.
              </p>
              <h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-300">Leading Customer Support</h2>
              <p className="mt-6">
                We have a team of dedicated customer support representatives who are available 24/7 to answer your
                questions and help you with any issues that may arise. We also offer a variety of self-help resources
                to help you get started.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
