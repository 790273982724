import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import { Spinner } from "@material-tailwind/react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
    ChatBubbleOvalLeftEllipsisIcon,
    ChevronDoubleRightIcon,
    MapPinIcon,
    PaperAirplaneIcon,
    PhoneArrowDownLeftIcon
} from "@heroicons/react/24/outline";

export default function Contact() {
    const [loading, setLoading] = useState(false);
    const form = useRef();

    const validateEmail = (email) => {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(email);
    };

    const validatePhone = (phone) => {
        const regex = /^\d+$/;
        return regex.test(phone);
    };

    const sendEmail = (e) => {
        e.preventDefault();

        const firstName = form.current.first_name.value.trim();
        const lastName = form.current.last_name.value.trim();
        const email = form.current.email.value.trim();
        const phone = form.current.phone.value.trim();
        const message = form.current.message.value.trim();

        if (!firstName || !lastName || !email || !phone || !message) {
            toast.error('Please fill all the fields!');
            return;
        }

        if (!validateEmail(email)) {
            toast.error('Please enter a valid email address!');
            return;
        }

        if (!validatePhone(phone)) {
            toast.error('Please enter a valid phone number!');
            return;
        }

        setLoading(true);

        emailjs.sendForm('service_wpxbf6d', 'template_voauz8a', form.current, 'Tk1dNZB8jhi2mDy9v')
            .then((result) => {
                console.log(result.text);
                toast.success('Message sent successfully!');
                setLoading(false);
                form.current.reset(); // Reset the form after successful submission
            }, (error) => {
                console.log(error.text);
                toast.error('Message failed to send!');
                setLoading(false);
            });
    };

    return (
        <div className="bg-transparent sm:py-15 lg:py-28">
            {/* // heading */}
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
                <div className="mx-auto max-w-2xl lg:text-center">
                    <h2 className="text-base font-semibold leading-7 text-indigo-500">Contact us</h2>
                    <p className="mt-2 text-3xl font-bold tracking-tight text-gray-200 sm:text-4xl">
                        Get in touch with us
                    </p>
                    <p className="mt-6 text-lg leading-8 text-gray-400">
                        If you have any idea or project in mind, we'd love to help you with it
                    </p>
                </div>
            </div>

        <div className="flex flex-col sm:flex-row gap-x-12 justify-center p-4 md:p-8 sm:flex-wrap">
           

            <div className="flex flex-col max-w-2xl sm:py-28 lg:py-15 rounded-md p-5">
                <p className="text-sm font-bold text-gray-400">Get in touch</p>
                <p className="text-xs text-gray-500 mt-1">
                    If you have any idea or project in mind,
                    <span className="block mt-1 text-gray-700 font-bold">
                        we'd love to help you with it
                    </span>
                </p>

                <div className="mt-6 flex gap-x-2">
                    <PhoneArrowDownLeftIcon className="h-4 w-4 text-gray-600 font-bold" />
                    <p
                        onClick={() => window.open('https://wa.me/+923415721997', '_blank')}
                        className="text-xs text-gray-600 font-bold cursor-pointer hover:underline">
                        Click to initiate a WhatsApp chat
                    </p>
                </div>

                <div className="mt-12 flex flex-col gap-x-2">
                    <p className="text-sm font-bold text-gray-400">Chat with us</p>
                    <p className="text-xs text-gray-400 mt-1">Chat with our friendly team via live chat</p>

                    <div className="mt-6 flex gap-x-2">
                        <ChatBubbleOvalLeftEllipsisIcon className="h-4 w-4 text-gray-700 font-bold" />
                        <p className="text-xs text-gray-500 font-bold">Start a Live Chat</p>
                    </div>

                    <div className="mt-4 flex gap-x-2 cursor-pointer hover:underline"
                        onClick={() => window.open('mailto:rana5542123@gmail.com', '_blank')}>
                        <PaperAirplaneIcon className="h-4 w-4 text-gray-700 font-bold" />
                        <p className="text-xs text-gray-500 font-bold">Shoot us an email</p>
                    </div>

                    <div className="mt-4 flex gap-x-2 cursor-pointer hover:underline"
                        onClick={() => window.open('https://www.linkedin.com/in/zaindev/', '_blank')}>
                        <ChevronDoubleRightIcon className="h-4 w-4 text-gray-700 font-bold" />
                        <p className="text-xs text-gray-500 font-bold">Message us on LinkedIn</p>
                    </div>
                </div>

                <p className="mt-10 text-sm font-bold text-gray-400">Visit Us</p>
                <p className="text-xs text-gray-400 mt-1">
                    Headquartered in the heart of the San Francisco
                    <span className="block mt-1 text-gray-700 font-bold">
                        Bay Area
                    </span>
                </p>

                <div className="mt-6 flex gap-x-2">
                    <MapPinIcon className="h-4 w-4 text-gray-700 font-bold" />
                    <p className="text-xs text-gray-700 font-bold">
                        Park Ave, Emeryville,
                        <span className="block mt-1 text-gray-700">
                            San Francisco Bay Area, CA 94608, USA
                        </span>
                    </p>
                </div>
            </div>

            <form className="flex flex-col max-w-2xl sm:py-28 lg:py-15 rounded-md p-5" ref={form}>
                <div className="flex flex-col sm:flex-row gap-x-4">
                    <div className="flex flex-col mt-4">
                        <label className="text-xs font-bold text-gray-700">First Name</label>
                        <input
                            className="border border-gray-900 rounded-md p-2 mt-1 text-xs bg-black text-gray-600"
                            type="text"
                            name="first_name"
                            placeholder="Enter your first name" />
                    </div>

                    <div className="flex flex-col mt-4">
                        <label className="text-xs font-bold text-gray-600">Last Name</label>
                        <input
                            className="border border-gray-900 rounded-md p-2 mt-1 text-xs bg-black text-gray-600"
                            type="text"
                            name="last_name"
                            placeholder="Enter your last name" />
                    </div>
                </div>

                <div className="flex flex-col mt-4">
                    <label className="text-xs font-bold text-gray-600">Email</label>
                    <input
                        className="border border-gray-900 rounded-md p-2 mt-1 text-xs bg-black text-gray-600"
                        type="text"
                        name="email"
                        placeholder="Enter your email" />
                </div>

                <div className="flex flex-col mt-4">
                    <label className="text-xs font-bold text-gray-600">Phone Number</label>
                    <input
                        className="border border-gray-900 rounded-md p-2 mt-1 text-xs bg-black text-gray-600"
                        type="text"
                        name="phone"
                        placeholder="Enter your phone number" />
                </div>

                <div className="flex flex-col mt-4">
                    <label className="text-xs font-bold text-gray-700">Message</label>
                    <textarea
                        className="border border-gray-900 rounded-md p-2 mt-1 text-xs bg-black h-32 text-gray-600"
                        name="message"
                        placeholder="Enter your message" />
                </div>

                <div className="flex flex-col mt-4">
                    <button
                        onClick={sendEmail}
                        className="bg-white text-black font-bold text-xs rounded-full p-2 mt-1 hover:bg-gray-400 cursor-pointer">
                        {loading ?
                            <Spinner className="h-5 w-5 inline-block" color="white" />
                            : 'Send Message'}
                    </button>
                </div>
                <ToastContainer
                    position="bottom-right"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="light"
                />

            </form>
        </div>
    </div>
    );
}
