import React, { useState } from "react";

const PortfolioTab = ({ label, isActive, onClick }) => (
  <li className={`relative ${isActive ? 'text-blue-600' : 'text-gray-500'}`}>
    <button onClick={onClick}>{label}</button>
    {isActive && (
      <span className="absolute left-1/2 transform -translate-x-1/2 mt-7 h-1 w-1 bg-blue-900 rounded-full"></span>
    )}
  </li>
);

const PortfolioVideo = ({ src, alt }) => {
  return (
    <video
      className="rounded-lg shadow-lg bg-gray-900 object-cover m-0"
      src={src}
      alt={alt}
      muted
      controlsList="nodownload"
      autoPlay
      loop
      style={{ width: "100%", height: "70%", margin: 0 }}
    />
  );
};


const PortfolioSection = () => {
  const [activeTab, setActiveTab] = useState("tab_0");

  const tabs = [
    { id: "tab_0", label: "Website" },
    { id: "tab_1", label: "Application" },
    { id: "tab_2", label: "More" },
  ];

  const videos = {
    tab_0: [
      "https://res.cloudinary.com/dp6prb7kr/video/upload/v1710748214/WhatsApp_Video_2024-03-13_at_12.32.45_AM_nhwqux.mp4",
      "https://res.cloudinary.com/dp6prb7kr/video/upload/v1710748222/WhatsApp_Video_2024-03-13_at_12.32.41_AM_2_n92tzm.mp4",
      "https://res.cloudinary.com/dp6prb7kr/video/upload/v1710747876/WhatsApp_Video_2024-03-13_at_12.32.50_AM_jyhdxa.mp4",
      "https://res.cloudinary.com/dp6prb7kr/video/upload/v1710747879/WhatsApp_Video_2024-03-13_at_12.32.50_AM_2_cbgebj.mp4",
      "https://res.cloudinary.com/dp6prb7kr/video/upload/v1710747878/WhatsApp_Video_2024-03-13_at_12.32.51_AM_plxv61.mp4",
      "https://res.cloudinary.com/dp6prb7kr/video/upload/v1710748224/WhatsApp_Video_2024-03-13_at_12.32.45_AM_2_qfowrf.mp4",
      "https://res.cloudinary.com/dp6prb7kr/video/upload/v1710748219/WhatsApp_Video_2024-03-13_at_12.32.42_AM_1_ikd11g.mp4",
      "https://res.cloudinary.com/dp6prb7kr/video/upload/v1710748222/WhatsApp_Video_2024-03-13_at_12.32.39_AM_1_uwnfou.mp4",
    ],
    tab_1: [
      "https://res.cloudinary.com/dp6prb7kr/video/upload/v1710748712/WhatsApp_Video_2024-03-13_at_12.32.44_AM_1_udtzwv.mp4",
      "https://res.cloudinary.com/dp6prb7kr/video/upload/v1710748716/WhatsApp_Video_2024-03-13_at_12.32.44_AM_2_cjdwlr.mp4",
      "https://res.cloudinary.com/dp6prb7kr/video/upload/v1710748723/WhatsApp_Video_2024-03-13_at_12.32.36_AM_1_p85e9b.mp4",
      "https://res.cloudinary.com/dp6prb7kr/video/upload/v1710748740/WhatsApp_Video_2024-03-13_at_12.32.44_AM_f333ra.mp4",
      "https://res.cloudinary.com/dp6prb7kr/video/upload/v1710748734/WhatsApp_Video_2024-03-13_at_12.32.41_AM_1_er4bax.mp4",
      "https://res.cloudinary.com/dp6prb7kr/video/upload/v1710748736/WhatsApp_Video_2024-03-13_at_12.32.37_AM_xuncov.mp4",
      "https://res.cloudinary.com/dp6prb7kr/video/upload/v1710748721/WhatsApp_Video_2024-03-13_at_12.32.41_AM_eqa0jb.mp4",
      "https://res.cloudinary.com/dp6prb7kr/video/upload/v1710748733/WhatsApp_Video_2024-03-13_at_12.32.36_AM_2_nkgdpi.mp4",
    ],
    tab_2: [
      "https://res.cloudinary.com/dp6prb7kr/video/upload/v1710748720/WhatsApp_Video_2024-03-13_at_12.32.38_AM_p9qtl9.mp4",
      "https://res.cloudinary.com/dp6prb7kr/video/upload/v1710748734/WhatsApp_Video_2024-03-13_at_12.32.35_AM_madqgk.mp4",
      "https://res.cloudinary.com/dp6prb7kr/video/upload/v1710748728/WhatsApp_Video_2024-03-13_at_12.32.36_AM_pnebki.mp4",
      "https://res.cloudinary.com/dp6prb7kr/video/upload/v1710748719/WhatsApp_Video_2024-03-13_at_12.32.38_AM_1_lopgac.mp4",
    ],
  };

  return (
    <section className="cs-portfolio_section bg-black py-16 px-4 md:px-0">

      <div className="container mx-auto">
        <div className="cs-tabs cs-style1">
          <div className="cs-tabs_heading flex justify-between items-center">
            <div className="cs-section_heading cs-style1">
              <h3 className="cs-section_subtitle text-gray-500">Some recent work</h3>
              <h2 className="cs-section_title text-4xl font-semibold text-white">
                Our Portfolios</h2>
            </div>
            <ul className="flex gap-4">
              {tabs.map((tab) => (
                <PortfolioTab
                  key={tab.id}
                  label={tab.label}
                  isActive={activeTab === tab.id}
                  onClick={() => setActiveTab(tab.id)}
                />
              ))}
            </ul>
          </div>
          <div className="cs-height_85 cs-height_lg_40 my-8"></div>
          <div className="cs-tabs_content grid grid-cols-1 gap-y-4 md:gap-y-8">
            <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-4 gap-x-4 gap-y-0 p-4">
              {videos[activeTab].map((videoSrc, index) => (
                <PortfolioVideo key={index} src={videoSrc} alt={`Portfolio Video ${index + 1}`} />
              ))}
            </div>
          </div>

        </div>
      </div>
    </section>
  );
};

export default PortfolioSection;
