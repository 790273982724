import React, { useState, useEffect } from 'react';

const DelayedSuspense = ({ delay, fallback, children }) => {
    const [isDelayed, setIsDelayed] = useState(true);

    useEffect(() => {
        const timer = setTimeout(() => {
            setIsDelayed(false);
        }, delay);

        return () => clearTimeout(timer);
    }, [delay]);

    if (isDelayed) {
        return fallback;
    }

    return <React.Suspense fallback={fallback}>{children}</React.Suspense>;
};

export default DelayedSuspense;
