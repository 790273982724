const posts = [
  {
    id: 1,
    title: 'Mastering Ecommerce Analytics',
    href: '#',
    description: "Partnering with Skylane for years has proven to be a transformative experience. Their expertise in ecommerce solutions is unmatched, offering personalized support to kickstart your business journey.",
    date: 'Mar 16, 2023',
    datetime: '2020-03-16',
    category: { title: 'Ecommerce', href: '#' },
    author: {
      name: 'Michael Foster',
      role: 'CEO Steelcase',
      href: '#',
      imageUrl: 'https://images.unsplash.com/photo-1655249493799-9cee4fe983bb?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3wxMjA3fDB8MXxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&q=80&w=1080',
    },
  },
  {
    id: 2,
    title: 'Innovative Banking Dashboards',
    href: '#',
    description: "Skylane's commitment shines in their project delivery — on time, within budget, and beyond expectations. Their proactive approach is ideal for anyone starting out or scaling up.",
    date: 'July 15, 2023',
    datetime: '2020-03-10',
    category: { title: 'Marketing', href: '#' },
    author: {
      name: 'Dries Vincent',
      role: 'Co-Founder / CTO',
      href: '#',
      imageUrl: 'https://images.unsplash.com/photo-1508214751196-bcfd4ca60f91?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3wxMjA3fDB8MXxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&q=80&w=1080',
    },
  },
  {
    id: 3,
    title: 'Travel Smarter with Our App',
    href: '#',
    description: "Experience seamless travel planning with Skylane’s app, crafted to enhance your travel adventures. Start your journey with a team that supports you every step of the way.",
    date: 'Jan 02, 2024',
    datetime: '2020-03-16',
    category: { title: 'Travel', href: '#' },
    author: {
      name: 'Lindsay Walton',
      role: 'Founder / CEO',
      href: '#',
      imageUrl: 'https://images.unsplash.com/photo-1515938736719-95b568dc8dd8?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3wxMjA3fDB8MXxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&q=80&w=1080',
    },
  }
  // More posts...
];

export default function Example() {
  return (
    <div className="bg-black py-24 sm:py-20 mb-10">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:mx-0">
          <h2 className="text-3xl font-bold tracking-tight text-gray-200 sm:text-4xl">Customer Success Stories</h2>
          <p className="mt-2 text-lg leading-8 text-gray-500">
            Discover why our customers trust us to power their success. Start your journey with Skylane today.
          </p>
        </div>
        <div className="mx-auto mt-10 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 border-t border-gray-600 pt-10 sm:mt-16 sm:pt-16 lg:mx-0 lg:max-w-none lg:grid-cols-3">
          {posts.map((post) => (
            <article key={post.id} className="flex max-w-xl flex-col items-start justify-between">
              <div className="flex items-center gap-x-4 text-xs">
                <time dateTime={post.datetime} className="text-gray-500">
                  {post.date}
                </time>
                <a
                  href={post.category.href}
                  className="relative z-10 rounded-full bg-gray-900 px-3 py-1.5 font-medium text-gray-400 hover:bg-gray-100"
                >
                  {post.category.title}
                </a>
              </div>
              <div className="group relative">
                <h3 className="mt-3 text-lg font-semibold leading-6 text-gray-300 group-hover:text-gray-300">
                  <a>
                    <span className="absolute inset-0" />
                    {post.title}
                  </a>
                </h3>
                <p className="mt-5 line-clamp-3 text-sm leading-6 text-gray-400">{post.description}</p>
              </div>
              <div className="relative mt-8 flex items-center gap-x-4">
                <img src={post.author.imageUrl} alt="" className="h-10 w-10 rounded-full bg-gray-50 flex-shrink-0 object-cover" />
                <div className="text-sm leading-6">
                  <p className="font-semibold text-indigo-500">
                    <a href={post.author.href}>
                      <span className="absolute inset-0" />
                      {post.author.name}
                    </a>
                  </p>
                  <p className="text-gray-600">{post.author.role}</p>
                </div>
              </div>
            </article>
          ))}
        </div>
      </div>
    </div>
  )
}
