import { ArrowPathIcon, CloudArrowUpIcon, FingerPrintIcon, LockClosedIcon, PaintBrushIcon } from '@heroicons/react/24/outline'
import Details from './details'
import { ComputerOutlined, MobileScreenShare } from '@mui/icons-material'
const features = [
  {
    name: 'Data Science & AI',
    description:
      'Our team of data scientists and AI experts can help you build intelligent solutions that can help you make better decisions and automate your business processes.',
    icon: CloudArrowUpIcon,
  },
  {
    name: 'Website Development',
    description:
      'We can help you build a website that will help you reach your target audience and grow your business. We have experience building websites for small businesses, startups, and large enterprises.',
    icon: ComputerOutlined,
  },
  {
    name: 'Mobile App Development',
    description:
      'Mobile apps are a great way to reach your customers and grow your business. We can help you build a mobile app that will help you reach your target audience and grow your business.',
    icon: MobileScreenShare,
  },
  {
    name: 'UI/UX Design',
    description:
      'We have a team of talented designers who can help you design a beautiful website or mobile app that will help you reach your target audience and grow your business.',
    icon: PaintBrushIcon,
  },
]

export default function About() {
  return (
    <div className="bg-black">
    <div className="bg-black py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:text-center">
          <h2 className="text-base font-semibold leading-7 text-indigo-500">About us</h2>
          <p className="mt-2 text-3xl font-bold tracking-tight text-gray-200 sm:text-4xl">
            Everything your business needs to succeed
          </p>
          <p className="mt-6 text-lg leading-8 text-gray-400">
            Skylane 
            is a full-service Software Development Company that helps businesses accelerate their growth with the help of
            cutting-edge technology and software solutions. We are a team of 100+ highly-skilled engineers, UI/UX
            designers, and project managers who are always ready to take on new challenges to help businesses scale.

          </p>
        </div>
        <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-4xl">
          <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none lg:grid-cols-2 lg:gap-y-16">
            {features.map((feature) => (
              <div key={feature.name} className="relative pl-16">
                <dt className="text-base font-semibold leading-7 text-gray-300">
                  <div className="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-gray-900">
                    <feature.icon className="h-6 w-6 text-white" aria-hidden="true" />
                  </div>
                  {feature.name}
                </dt>
                <dd className="mt-2 text-base leading-7 text-gray-400">{feature.description}</dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
    <Details />
    </div>
  )
}
