import logo1 from '../asstes/Microsoft_logo_(2012).svg.png'
import logo2 from '../asstes/Airbnb.png'
import logo3 from '../asstes/Almond.png'
import logo4 from '../asstes/logo4.png'
import logo5 from '../asstes/logo5.png'

export default function Partners() {
  return (
    <div className="py-1 bg-black">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="text-center">
          <h3 className="text-3xl font-extrabold tracking-tight text-white sm:text-4xl">
            Trusted by the World's Best
          </h3>
          <p className="mt-4 text-lg leading-8 text-gray-400">
            Join over 5,000 businesses that rely on our platform to grow.
          </p>
        </div>
        <div className="mt-12 grid grid-cols-2 gap-y-8 gap-x-8 sm:grid-cols-3 lg:grid-cols-5 lg:gap-y-10">
          <img
            className="max-h-16 w-full object-contain filter grayscale hover:filter-none transition ease-in-out duration-300"
            src={logo1}
            alt="Microsoft"
          />
          <img
            className="max-h-16 w-full object-contain filter grayscale hover:filter-none transition ease-in-out duration-300"
            src={logo2}
            alt="Airbnb"
          />
          <img
            className="max-h-16 w-full object-contain filter grayscale hover:filter-none transition ease-in-out duration-300"
            src={logo3}
            alt="Almond"
          />
          <img
            className="max-h-16 w-full object-contain filter grayscale hover:filter-none transition ease-in-out duration-300"
            src={logo4}
            alt="Logo4"
          />
          <img
            className="max-h-16 w-full object-contain filter grayscale hover:filter-none transition ease-in-out duration-300"
            src={logo5}
            alt="Logo5"
          />
        </div>
      </div>
    </div>
  );
}
