import React from "react";
import { NavbarWithMegaMenu } from "./components/Navbar";
import Aos from "aos";
import "aos/dist/aos.css";
import { FooterWithSitemap } from "./components/footer";

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import Home from "./Home";
import About from "./components/about/aboutus";
import Contact from "./components/contact";

export default function App() {

  Aos.init({ duration: 1000 });

  return (
    <div className="flex flex-col bg-black font-sans">
      <NavbarWithMegaMenu />
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/workwithus" element={<About />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
      </Router>
      <FooterWithSitemap />
    </div>
  );
}